import Vue from "vue";
import Router from "vue-router";



Vue.use(Router);

const routes = [{
        path: "/",
        name: "App",
        component: () =>
            import ("@/layouts/Default"),
        redirect: "",
        beforeEnter(to, from, next) {
            const admin = localStorage.getItem("admin-token");
            const user = localStorage.getItem("user-token");

            if (admin) {
                next();
            } else if (user) {
                next("/account/user");
            } else {
                next("/identifier");
            }
        },

        meta: {
            title: "Orang Code ",
            metaTags: [{
                    name: "description",
                    content: "OrangCode Nodejs backend development platforme",
                },
                {
                    property: "og:description",
                    content: "OrangCode Nodejs backend development platforme",
                },
            ],
        },
        children: [

        ]

    },
    {
        path: "/account/user",
        name: "UserAccount",
        component: () =>
            import ("@/layouts/Auth"),
        beforeEnter(to, from, next) {
            const userToken = localStorage.getItem("user-token");

            if (userToken) {
                next();
            } else {
                next("/identifier");
            }
        },
        redirect: "/account/user/authorize-app-to-get-auth-information",

        meta: {
            title: "Orang Code ",
            metaTags: [{
                    name: "description",
                    content: "OrangCode Nodejs backend development platforme",
                },
                {
                    property: "og:description",
                    content: "OrangCode Nodejs backend development platforme",
                },
            ],
        },
        children: [{
            path: "/account/user/authorize-app-to-get-auth-information",
            name: "AuthorizeAppToGetAuthInformation",
            component: () =>
                import ("@/views/Account/AuthorizeAppToGetAuthInformation"),
        }, ]

    },
    {
        path: "/identifier",
        name: "AuthApp",
        component: () =>
            import ("@/layouts/Auth"),
        beforeEnter(to, from, next) {
            const redirectToUserAccount = localStorage.getItem("redirect-to-user-account");
            // const admin = localStorage.getItem("admin-token");
            // const user = localStorage.getItem("user-token");

            if (redirectToUserAccount) {
                next("/account/user/authorize-app-to-get-auth-information")

            } else {

                next();
            }


        },
        // redirect: "/identifier/signin",
        meta: {
            title: "Orang Code ",
            metaTags: [{
                    name: "description",
                    content: "Orang Code Signin page",
                },
                {
                    property: "og:description",
                    content: "Orang Code Signin page",
                },
            ],
        },
        children: [{
                path: "/identifier",
                name: "RedirectToDefaultOrangCodeApp",
                component: () =>
                    import ("@/views/Identifier/RedirectToDefaultOrangCodeApp"),
            },
            {
                path: "/identifier/signin",
                name: "Signin",
                component: () =>
                    import ("@/views/Identifier/Login"),
            },
            {
                path: "/identifier/signup",
                name: "SignUp",
                component: () =>
                    import ("@/views/Identifier/SignUp"),
            },
            {
                path: "/identifier/forget-password",
                name: "ForgetPassword",
                component: () =>
                    import ("@/views/Identifier/ForgetPassword"),
            },
            {
                path: "/identifier/verify-restauration-code",
                name: "VerifyRestaurationCode",
                component: () =>
                    import ("@/views/Identifier/VerifyRestaurationCode"),
            },
            {
                path: "/identifier/verify-email",
                name: "VerifyEmail",
                component: () =>
                    import ("@/views/Identifier/VerifyEmail"),
            },
            {
                path: "/identifier/redirect-to-app",
                name: "RedirectToApp",
                component: () =>
                    import ("@/views/Identifier/RedirectToApp"),
            },
            {
                path: "/identifier/new-password",
                name: "NewPassword",
                component: () =>
                    import ("@/views/Identifier/NewPassword"),
            },
            {
                path: "/identifier/double-authentification-verification",
                name: "VerifyDoubleAuthentificationCode",
                component: () =>
                    import ("@/views/Identifier/VerifyDoubleAuthentificationCode"),
            },
            {
                path: "/identifier/:authProvider/login-callback",
                name: "AuthProviderLoginCallback",
                component: () =>
                    import ("@/views/Identifier/AuthProviderLoginCallback"),
            },


        ]
    },
    {
        path: "*",
        redirect: "/",
    },
];


const router = new Router({
    mode: "history",
    routes,
    base: '/',
});

// ...

// This callback runs before every route change, including on page load.

router.beforeEach((to, from, next) => {


    // This goes through the matched routes from last to first, finding the closest route with a title.
    // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
    // `/nested`'s will be chosen.
    const nearestWithTitle = to.matched
        .slice()
        .reverse()
        .find((r) => r.meta && r.meta.title);

    // Find the nearest route element with meta tags.
    const nearestWithMeta = to.matched
        .slice()
        .reverse()
        .find((r) => r.meta && r.meta.metaTags);

    // If a route with a title was found, set the document (page) title to that value.
    if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(
        document.querySelectorAll("[data-vue-router-controlled]")
    ).map((el) => el.parentNode.removeChild(el));

    // Skip rendering meta tags if there are none.
    if (!nearestWithMeta) return next();

    // Turn the meta tag definitions into actual elements in the head.
    nearestWithMeta.meta.metaTags
        .map((tagDef) => {
            const tag = document.createElement("meta");

            Object.keys(tagDef).forEach((key) => {
                tag.setAttribute(key, tagDef[key]);
            });

            // We use this to track which meta tags we create so we don't interfere with other ones.
            tag.setAttribute("data-vue-router-controlled", "");

            return tag;
        })
        // Add the meta tags to the document head.
        .forEach((tag) => document.head.appendChild(tag));

    next();
});

// ...

export default router;